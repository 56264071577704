import { PostgreSQL, sql } from "@codemirror/lang-sql";
import CodeMirror from "@uiw/react-codemirror";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalState } from "../../../components/common/GlobalVariables";
import { CommonTemplate } from "../../../components/common/containerAvailability/CommonScript";
import SavedQuery from "../../search/SavedQuery";
import { SearchTemplate } from "../../search/SearchScript";
import { Stream } from "../StreamScript";

function LoadDetectionSearch(props) {
  const autosuggestions = props.autosuggestions;
  const [searchText] = useGlobalState("bnfx_global_stream_detction_serachbar");
  const [isExpanded] = useGlobalState("search_collapsed");
  let detectionDataType = props.detectionDataType;

  const onChangeSql = useCallback((value, viewUpdate) => {
    Stream.detectionSql = value;
  }, []);
  const [isLive, setIsLive] = useState(false);
  const prevLiveModeRef = useRef(isLive);
  let intervalid = null;

  const toggleLiveMode = () => {
    setIsLive((prev) => !prev);
  };
  useEffect(() => {
    const prevLiveMode = prevLiveModeRef.current;
    prevLiveModeRef.current = isLive; // Update the previous state value
    if (isLive) {
      // Live mode is turned on

      Stream.raweventdatalivemode();
      intervalid = setInterval(
        () => {
          if (isLive) {
            Stream.raweventliveapi();
          } else {
            clearInterval(intervalid);
          }
        },
        5000,
        isLive
      ); // 5 seconds interval
      window.localStorage.setItem("liveMode_intervalId", intervalid);
    
      // Cleanup interval when component unmounts or live mode is turned of
    } else {
      // Live mode is turned off
      intervalid = window.localStorage.getItem("liveMode_intervalId");
      if (intervalid) {
        clearInterval(intervalid);
      }

      // Call deletequerid() only if live mode is transitioning from ON to OFF
      if (prevLiveMode) {
        Stream.deletequerid();
      }
    }
    return () => {
      if(intervalid) { clearInterval(intervalid)};
    };
  }, [isLive]); // Effect depends on `isLive` state

  return (
    <div
      className="bxRightSearchBox mt-1"
      id={"bxRightSearchBox" + props.serachtype + props.streamid}
    >
      <div className="sidebar-search">
        <CodeMirror
          value={searchText}
          placeholder={"Type query to search " + props.serachtype + "...."}
          basicSetup={{
            lineNumbers: false,
            highlightActiveLineGutter: false,
            highlightActiveLine: false,
          }}
          theme={"dark"}
          minHeight="50px"
          extensions={sql({
            defaultTable: "detections",
            dialect: PostgreSQL,
            schema: {
              detections: autosuggestions ? autosuggestions : [],
            },
          })}
          onChange={onChangeSql}
        />
        {props.serachtype === "rawevents" ? (
          <div
            onClick={toggleLiveMode}
            className={`bnfx-rawevents-livemode ${isLive ? "live" : ""}`}
          >
            <span
              class="material-symbols-outlined"
              title={isLive ? "Live Mode ON" : "Live mode OFF"}
            >
              radio_button_checked
            </span>
          </div>
        ) : null}
        <div
          className="streamdownlaodicon"
          data-bs-toggle="modal"
          data-bs-target="#bxDownloadModal"
          onClick={() => {
            CommonTemplate.removedownloadnone();
            CommonTemplate.clearfillupdetails();
          }}
        >
          <span className="material-symbols-outlined ">download</span>
        </div>
        {props.serachtype !== "rawevents" ?<button
          className={`btn stream_saved_search_btn collapsed ${
            detectionDataType === "aggregationdata" ? "d-none" : ""
          } ${isExpanded ? "rotate" : ""}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSaveSearchStream"
          aria-expanded="false"
          aria-controls="collapseSaveSearch"
          onClick={() => SearchTemplate.handleToggle()}
        >
          <span className="material-symbols-outlined">expand_more</span>
        </button>:null}

        <button
          className=" btn btn-primary"
          type="submit"
          onClick={() => {
            Stream.serachresultonlclick(props.streamid, props.serachtype);
          }}
        >
          <span className="material-symbols-outlined">send</span>
        </button>
      </div>
      <SavedQuery></SavedQuery>
    </div>
  );
}

export default LoadDetectionSearch;
