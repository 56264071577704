import { useEffect } from "react";
import Select from "react-select";
import { Content } from "../ContentScript";
import CodeLayout from "../codepanel/CodeLayout";
import {
  DATA_TYPES,
  FORM_FOR_NON_PIPELINE_TERMINAL_NAME,
  HIDE_CODE_BLOCK_FOR_TYPE,
  INPUT_FIELDS_BY_TYPE,
} from "../contentConstants";
import ContentSelectDropDown from "./ContentSelectDropDown";
import { t } from "i18next";

export default function CreateEditNonStreamItem({
  nonStreamFields,
  type,
  dispatch,
  tenantsList,
  terminalName,
  isCodeGeneratorPanelOpen,
  codeGeneratorInputs,
  codeGeneratorFormFields,
  isCodeGeneratorLoading,
  testCodeFormValues,
  errors,
  onCloseEditOrCreate,
  isTestCodeLoading,
  secretsOrVariablesFields,
}) {
  useEffect(() => {
    Content.getTenantsList(dispatch);
  }, [dispatch]);
  return (
    <>
      {FORM_FOR_NON_PIPELINE_TERMINAL_NAME.includes(terminalName) && (
        <SecretsAndVariablesForm
          secretsOrVariablesFields={secretsOrVariablesFields}
          type={type}
          dispatch={dispatch}
          tenantsList={tenantsList}
          terminalName={terminalName}
          errors={errors}
          onCloseEditOrCreate={onCloseEditOrCreate}
        />
      )}

      {!FORM_FOR_NON_PIPELINE_TERMINAL_NAME.includes(terminalName) && (
        <PipelineElementsForm
          nonStreamFields={nonStreamFields}
          type={type}
          dispatch={dispatch}
          tenantsList={tenantsList}
          terminalName={terminalName}
          isCodeGeneratorPanelOpen={isCodeGeneratorPanelOpen}
          codeGeneratorInputs={codeGeneratorInputs}
          codeGeneratorFormFields={codeGeneratorFormFields}
          isCodeGeneratorLoading={isCodeGeneratorLoading}
          testCodeFormValues={testCodeFormValues}
          errors={errors}
          onCloseEditOrCreate={onCloseEditOrCreate}
          isTestCodeLoading={isTestCodeLoading}
        />
      )}
    </>
  );
}

const PipelineElementsForm = ({
  nonStreamFields,
  type,
  dispatch,
  tenantsList,
  terminalName,
  isCodeGeneratorPanelOpen,
  codeGeneratorInputs,
  codeGeneratorFormFields,
  isCodeGeneratorLoading,
  testCodeFormValues,
  errors,
  onCloseEditOrCreate,
  isTestCodeLoading,
}) => {
  return (
    <>
      <div className="bxContent_head p-0">
        <div className="btn_Group float-end d-flex align-items-center">
          {nonStreamFields.mode === "edit" && (
            <>
              <button
                type="button"
                className="btn btn-primary px-5 me-2 contentbutton"
                // data-bs-dismiss="offcanvas"
                onClick={() =>
                  Content.updateNonStreamData(dispatch, nonStreamFields)
                }
              >
                {t("name.save")}
              </button>

              <div className="content-options-dropdown">
                <button
                  className="dropdown-toggle arrow-none new-group-btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                    more_vert
                  </i>
                </button>
                <ul className="dropdown-menu contentactiondots">
                  <h6 className="ps-2 border-bottom pb-2">Actions</h6>
                  <li
                    className="contentDelete d-flex align-items-center"
                    title="Delete"
                    onClick={() =>
                      Content.deleteContentConfirmation(
                        dispatch,
                        nonStreamFields,
                        type
                      )
                    }
                  >
                    <span className="material-symbols-outlined">delete</span>
                    Delete
                  </li>
                  {(type === "dashboards" || type === "streams") && (
                    <li
                      className="contentPublish d-flex align-items-center"
                      title="Publish"
                      onClick={() =>
                        Content.publishComponent(
                          terminalName,
                          nonStreamFields?.componentData?.name,
                          nonStreamFields.tenant,
                          true
                        )
                      }
                    >
                      <span className="material-symbols-outlined">publish</span>
                      Publish
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}

          {nonStreamFields.mode !== "edit" && (
            <button
              type="button"
              className="btn btn-primary px-5 me-2 contentbutton"
              // data-bs-dismiss="offcanvas"
              onClick={() =>
                Content.saveNonStreamData(
                  dispatch,
                  nonStreamFields,
                  terminalName,
                  type
                )
              }
            >
              {t("name.save")}
            </button>
          )}
        </div>
        <div className="d-flex align-items-center">
          <button
            className="BackArrow"
            type="button"
            aria-label="Close"
            onClick={() => onCloseEditOrCreate()}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </button>
          <h4 className="m-0 text-white ms-3">
            {nonStreamFields.path
              ? nonStreamFields.title
              : "Add " + terminalName}
          </h4>
        </div>
      </div>
      <div id="" className="bxSettingPage_editListBox">
        <div action="" className="bxSP_nonstream_form">
          {nonStreamFields.mode !== "edit" &&
            tenantsList &&
            tenantsList.length > 1 && (
              <div className="bxSTP-states form-group mb-3">
                <label>Tenant</label>
                <Select
                  className={`form-control ${
                    errors?.tenant ? "border-danger " : ""
                  }`}
                  options={tenantsList || []}
                  onChange={(option) =>
                    Content.onNoStreamItemChange(
                      { target: { name: "tenant", value: option.value } },
                      dispatch
                    )
                  }
                  value={(tenantsList || [])?.find(
                    (option) => option.value === nonStreamFields?.tenant
                  )}
                ></Select>
              </div>
            )}
          <div className="bnfx-content-namedesc-details">
            <div className="bxSTP-states form-group mb-3">
              <label>Title</label>
              <input
                className={`bxform-control ${
                  errors?.title ? "border-danger " : ""
                }`}
                type="text"
                name="title"
                value={nonStreamFields?.title}
                onChange={(e) => Content.onNoStreamItemChange(e, dispatch)}
              />
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
            <div className="bxSTP-states form-group">
              <label>description</label>
              <textarea
                name="description"
                id=""
                cols="30"
                rows="2"
                className={`bxform-control ${
                  errors?.description ? "border-danger " : ""
                }`}
                value={nonStreamFields?.description}
                onChange={(e) => Content.onNoStreamItemChange(e, dispatch)}
              ></textarea>
              <small className="d-block text-end bxFontSize">
                {t("required")}
              </small>
            </div>
          </div>

          {INPUT_FIELDS_BY_TYPE[type] && (
            <div className="bnfx-content-contentoptions-details">
              {INPUT_FIELDS_BY_TYPE[type]?.map((field) => {
                return (
                  <InputSelector
                    dispatch={dispatch}
                    field={field}
                    errors={errors}
                    tenant={nonStreamFields.tenant}
                    selectedValue={nonStreamFields[field.name]}
                  />
                );
              })}
            </div>
          )}

          {!HIDE_CODE_BLOCK_FOR_TYPE.includes(type) && (
            <main className="app ContentPageLayout">
              <section className="contentNonestreamLayout">
                <CodeLayout
                  nonStreamFields={nonStreamFields}
                  type={type}
                  dispatch={dispatch}
                  tenantsList={tenantsList}
                  terminalName={terminalName}
                  isCodeGeneratorPanelOpen={isCodeGeneratorPanelOpen}
                  codeGeneratorInputs={codeGeneratorInputs}
                  codeGeneratorFormFields={codeGeneratorFormFields}
                  isCodeGeneratorLoading={isCodeGeneratorLoading}
                  testCodeFormValues={testCodeFormValues}
                  errors={errors}
                  onCloseEditOrCreate
                  isTestCodeLoading={isTestCodeLoading}
                />
              </section>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

const SecretsAndVariablesForm = ({
  secretsOrVariablesFields,
  type,
  dispatch,
  tenantsList,
  terminalName,
  errors,
  onCloseEditOrCreate,
}) => {
  return (
    <>
      <div className="bxContent_head p-0">
        <div className="btn_Group float-end d-flex align-items-center">
          {secretsOrVariablesFields.mode === "edit" && (
            <>
              <button
                type="button"
                className="btn btn-primary px-5 me-2 contentbutton"
                // data-bs-dismiss="offcanvas"
                onClick={() =>
                  Content.saveSecretOrVariableDetails(
                    dispatch,
                    secretsOrVariablesFields,
                    terminalName,
                    type
                  )
                }
              >
                {t("name.save")}
              </button>

              <div className="content-options-dropdown">
                <button
                  className="dropdown-toggle arrow-none new-group-btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                    more_vert
                  </i>
                </button>
                <ul className="dropdown-menu contentactiondots">
                  <h6 className="ps-2 border-bottom pb-2">Explore</h6>
                  <li
                    className="contentDelete d-flex align-items-center"
                    title="Delete"
                    onClick={() =>
                      Content.deleteSecretOrVariable(
                        dispatch,
                        secretsOrVariablesFields,
                        type
                      )
                    }
                  >
                    <span className="material-symbols-outlined">delete</span>
                    Delete
                  </li>
                </ul>
              </div>
            </>
          )}

          {secretsOrVariablesFields.mode !== "edit" && (
            <button
              type="button"
              className="btn btn-primary px-5 me-2 contentbutton"
              // data-bs-dismiss="offcanvas"
              onClick={() =>
                Content.saveSecretOrVariableDetails(
                  dispatch,
                  secretsOrVariablesFields,
                  terminalName,
                  type
                )
              }
            >
              {t("name.save")}
            </button>
          )}
        </div>
        <div className="d-flex align-items-center">
          <button
            className="BackArrow"
            type="button"
            aria-label="Close"
            onClick={() => onCloseEditOrCreate()}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </button>
          <h4 className="m-0 text-white ms-3">
            {(secretsOrVariablesFields.mode === "edit" ? "Update " : "Add ") +
              terminalName}
          </h4>
        </div>
      </div>
      <div id="" className="bxSettingPage_editListBox">
        <div action="" className="bxSP_nonstream_form">
          {secretsOrVariablesFields.mode !== "edit" &&
            tenantsList &&
            tenantsList.length > 1 && (
              <div className="bxSTP-states form-group mb-3">
                <label>Tenant</label>
                <Select
                  className={`form-control ${
                    errors?.tenant ? "border-danger " : ""
                  }`}
                  options={tenantsList || []}
                  onChange={(option) =>
                    Content.onSecretOrVariableItemChange(
                      { target: { name: "tenant", value: option.value } },
                      dispatch
                    )
                  }
                  value={(tenantsList || [])?.find(
                    (option) =>
                      option.value === secretsOrVariablesFields?.tenant
                  )}
                ></Select>
              </div>
            )}
          <div className="bnfx-content-namedesc-details">
            <div className="bxSTP-states form-group mb-3">
              <label>Source</label>
              <input
                className={`bxform-control ${
                  errors?.source ? "border-danger " : ""
                }`}
                type="text"
                name="source"
                disabled={secretsOrVariablesFields.mode === "edit"}
                value={secretsOrVariablesFields?.source}
                onChange={(e) =>
                  Content.onSecretOrVariableItemChange(e, dispatch)
                }
              />
            </div>

            <div className="bxSTP-states form-group mb-3">
              <label>Key</label>
              <input
                className={`bxform-control ${
                  errors?.key ? "border-danger " : ""
                }`}
                type="text"
                name="key"
                autoComplete="off"
                disabled={secretsOrVariablesFields.mode === "edit"}
                value={secretsOrVariablesFields?.key}
                onChange={(e) =>
                  Content.onSecretOrVariableItemChange(e, dispatch)
                }
              />
            </div>
            <div className="bxSTP-states form-group mb-3">
              <label>Value</label>
              <input
                className={`bxform-control ${
                  errors?.value ? "border-danger " : ""
                }`}
                type={terminalName === "secret" ? "password" : "text"}
                name="value"
                autoComplete="off"
                value={secretsOrVariablesFields?.value}
                onChange={(e) =>
                  Content.onSecretOrVariableItemChange(e, dispatch)
                }
              />
            </div>
            {secretsOrVariablesFields.mode !== "edit" && (
              <div className="bxSTP-states form-group mb-3">
                <label>Data Type</label>
                <Select
                  className={`form-control ${
                    errors?.type ? "border-danger " : ""
                  }`}
                  options={DATA_TYPES}
                  onChange={(option) =>
                    Content.onSecretOrVariableItemChange(
                      { target: { name: "type", value: option.value } },
                      dispatch
                    )
                  }
                  value={(DATA_TYPES || [])?.find(
                    (option) =>
                      option.value === secretsOrVariablesFields?.type
                  )}
                ></Select>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const INPUT_TYPES = ["text", "radio", "checkbox"];
const SELECT_TYPES = ["select"];
const MULTI_SELECT_TYPES = ["multiselect"];
const InputSelector = ({ dispatch, field, errors, selectedValue, tenant }) => {
  return (
    <>
      {INPUT_TYPES.includes(field.type) && (
        <div className="bxSTP-states form-group mb-3">
          <label>{field.label}</label>
          <input
            className="w-100 inputCustom"
            type={field.type}
            id={field.name}
            name={field.name}
            placeholder={`Enter ${field.name}`}
            autoComplete="off"
            value={selectedValue}
            onChange={(e) => Content.onNoStreamItemChange(e, dispatch)}
          />
        </div>
      )}

      {SELECT_TYPES.includes(field.type) && (
        <div className="bxSTP-states form-group mb-3">
          <label>{field.label}</label>

          <Select
            className={`form-control ${
              errors[field.name] ? "border-danger " : ""
            }`}
            options={field.options || []}
            onChange={(option) =>
              Content.onNoStreamItemChange(
                { target: { name: "schedule", value: option.value } },
                dispatch
              )
            }
            value={field?.options?.find(
              (option) => option.value === selectedValue
            )}
          ></Select>
        </div>
      )}

      {MULTI_SELECT_TYPES.includes(field.type) && (
        <div className="bxSTP-states form-group mb-3">
          <label>{field.label}</label>
          <ContentSelectDropDown
            className={`${errors[field.name] ? "border-danger " : ""}`}
            contenttype={field.name}
            tenant={tenant}
            streamFields={selectedValue}
            onChange={(option) => {
              Content.onNoStreamItemChange(
                { target: { name: field.name, value: option } },
                dispatch
              );
            }}
            multi={true}
          />
        </div>
      )}
    </>
  );
};
