import moment from "moment";
import { showError } from "../../components/common/DisplayError";
import {
  setGlobalState,
} from "../../components/common/GlobalVariables";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax, fetchData } from "../../components/common/Service";
import Pagination from "../../components/common/pagination/Pagination";
import ColumnChart from "../../components/highChart/ColumnChart";
import CumulativeStats from "./details/CumulativeStats";
import DetectionEvent from "./details/DetectionEvent";
import Metadata from "./details/Metadata";
import StreamDetailsLayout from "./details/StreamDetailsLayout";
import StreamListData from "./list/StreamListData";

import { t } from "i18next";
import "jquery-toast-plugin";
import React from "react";
import Swal from "sweetalert2";
import NoContent from "../../components/common/NoContent";
import ToastPopup from "../../components/common/ToastPopup";
import StreamsWrapperContainer from "./StreamsWrapperContainer";
import NoStreamsAvailable from "./details/NoStreamsAvailable";

import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import LoadDetectionSearch from "./details/LoadDetectionSearch";
import ActionList from "./list/ActionList";

import jwt from "jwt-decode";
import { Artifact } from "../../components/common/Artifacts/ArtifactScript";
import { PanelScript } from "../../components/common/GridPanel.js/PanelScript";
import BarChart from "../../components/highChart/BarChart";
import DonutChart from "../../components/highChart/DonutChart";
import StellarChart from "../../components/highChart/StellarChart";
import TrendChart from "../../components/highChart/TrendChart";
import { DownloadsScript } from "../download/DownloadsScript";
import DetectionList from "./details/DetectionList";
import NoDetections from "./details/NoDetections";
import NoStatCard from "./details/NoStatCard";
import RawEvent from "./details/RawEvent";
import SettingTenantLayout from "./details/SettingsIcon/SettingTenantLayout";
import StockTrendChart from "../../components/highChart/StockTrendChart";

const token = localStorage.getItem("token");
export const Stream = {
  searchtimeout: [],
  selected_stream: null,
  available_stream: null,
  detection_total: null,
  enabled_streams_total: null,
  available_streams_total: null,
  detectionSql: "",
  rendered: false,
  selected_days: 3,
  selectedTenant: null,
  selected_param: null,
  issettingeditable: false,
  retriveddata: null,
  selected_freetext: null,
  detectionDataType: "detectiondata",
  aggregationPresent: "hideaggregation",
  detectioncount: 0,
  raweventQueryid: "",
  chartInstance: null,
  lastRawEventsTrendTime: "",
  raweventRangeSelector: [],
  rawEventsLiveModeInterval: null,

  clearStreamlayout: () => {
    Stream.searchtimeout = [];
    Stream.selected_stream = null;
    Stream.available_stream = null;
    Stream.detection_total = null;
    Stream.enabled_streams_total = null;
    Stream.available_streams_total = null;
    Stream.detectionSql = "";
    Stream.lastRawEventsTrendTime = "";
    Stream.raweventRangeSelector = [];
    Stream.chartInstance = null;
  },

  init: () => {},

  selectStream: (stream) => {
    Stream.selected_stream = stream;
  },

  selectAvailable: (stream) => {
    Stream.available_stream = stream;
  },

  setDetectionTotal: (total) => {
    Stream.detection_total = total;
  },

  showAggregation: (aggregation) => {
    Stream.aggregationPresent = aggregation;
  },

  loadAvailableLayout: (offset) => {
    Stream.available_stream = null;
    Stream.listAvailableStreams(offset);
    Stream.getCount(false, "available_stream_search");
  },

  loadEnabledLayout: (offset) => {
    Stream.selected_stream = null;
    Stream.listEnabledStreams(offset);
    Stream.getCount(true, "enabled_stream_search");
  },

  loadDetailsLayout: (stream) => {
    setGlobalState("bnfx_global_stream_detction_serachbar", "");
    Stream.setDetectionfreetext(null);
    Stream.getSavedSettingCount(stream?._id);
    Stream.clearStreamlayout();
    Stream.selectStream(stream);

    Stream.renderMetaData(stream);

    Stream.getStats(stream);
  },

  loadChartData: (objectid) => {
    const request = {
      objectid: objectid,
      limit: 50,
      offset: 0,
      report: false,
      enabled: true,
    };

    ajax(
      "post",
      requestURI["stream.chart"],
      null,
      request,
      Stream.renderchartdata,
      showError,
      [objectid]
    );
  },

  processData: (data) => {
    const groupedData = {};

    Object.values(data).forEach((item) => {
      const { insert_date, stattype, total_count } = item;
      if (!groupedData[stattype]) {
        groupedData[stattype] = { name: stattype.toLowerCase(), data: [] };
      }
      const index = groupedData[stattype].data.findIndex(
        (entry) => entry.insert_date === insert_date
      );
      if (index === -1) {
        groupedData[stattype].data.push(parseInt(total_count));
      } else {
        groupedData[stattype].data[index] += parseInt(total_count);
      }
    });

    return Object.values(groupedData);
  },

  renderchartdata: (result, args) => {
    const objectid = args[0];
    const chartcontainer = "bnfxStreamColumnChart" + objectid;

    if (result.data.response.status === "failed") {
      const container = document.querySelector("#" + chartcontainer);
      const data = (
        <NoContent
          id={"nographfound"}
          title={t("Graph Not Found For Last Seven Days.")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);

      const dayscontainer = document.querySelector("#daystitle");
      const daysdata = null;
      CommonTemplate.ContainerAvailability(dayscontainer, daysdata);
    } else {
      result = result.data.response.result;

      const currentDate = new Date();
      const lastSevenDays = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() - i);
        lastSevenDays.push(date.toISOString().split("T")[0]);
      }

      // Create a new object with missing dates and stattype names
      const stattypes = [
        "FILTERED",
        "PUBLISHED",
        "DETECTIONS",
        "TRANSFORMED",
        "CORRELATED",
        "AUTOMATIONS",
      ];
      const newData = lastSevenDays.flatMap((date) => {
        return stattypes.map((stattype) => {
          const existingItem = result.find(
            (item) => item.insert_date === date && item.stattype === stattype
          );
          if (existingItem) {
            return existingItem;
          } else {
            return { insert_date: date, stattype: stattype, total_count: "0" };
          }
        });
      });

      const series = Stream.processData(newData);

      const categories = Object.values(newData).reduce((dates, item) => {
        const insertDate = item.insert_date;
        if (dates.indexOf(insertDate) === -1) {
          dates.push(insertDate);
        }

        return dates;
      }, []);

      const container = document.querySelector("#" + chartcontainer);
      const data = (
        <ColumnChart
          colors={["#00876c", "#3d9c73", "#63b179", "#88c580", "#aed987"]}
          series={series}
          categories={categories}
          container={chartcontainer}
          height={80}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },
  search: (id) => {
    const freetext = document.querySelector("#" + id).value;

    if (freetext.length > 3 || freetext.length === 0) {
      if (Stream.searchtimeout && Stream.searchtimeout.length > 0) {
        for (let index = 0; index < Stream.searchtimeout.length; index++) {
          window.clearTimeout(Stream.searchtimeout[index]);
        }
      }
      Stream.searchtimeout.push(
        setTimeout(() => {
          if (id === "enabled_stream_search") {
            Stream.loadEnabledLayout(0);
          } else if (id === "available_stream_search") {
            Stream.loadAvailableLayout(0);
          }
          // else {
          //   Stream.getDetections(Stream.selected_stream?._id);
          // }
        }, 2000)
      );
    }
  },

  listAvailableStreams: (offset) => {
    let freetext = CommonTemplate.getSearchText("available_stream_search");

    const request = {
      limit: Math.floor(
        Stream.adjustAvailableTabContentHeight() /
          (window.innerWidth > 1500 ? 71 : 51)
      ),
      offset: offset ? offset : 0,
      query: freetext ? freetext : undefined,
      enabled: false,
      sortorder: false,
      sortcol: "name",
      type: "packages.streams",
    };

    ajax(
      "post",
      requestURI["streams.listwithoutcomponents"],
      null,
      request,
      Stream.renderAvailableStreams,
      showError
    );
  },

  listEnabledStreams: (offset) => {
    let freetext = CommonTemplate.getSearchText("enabled_stream_search");
    const request = {
      limit: Math.floor(
        Stream.adjustEnabledTabContentHeight() /
          (window.innerWidth > 1500 ? 71 : 51)
      ),
      offset: offset ? offset : 0,
      query: freetext ? freetext : undefined,
      enabled: true,
      sortorder: false,
      sortcol: "lastupdated",
      type: "packages.streams",
    };

    ajax(
      "post",
      requestURI["streams.listwithoutcomponents"],
      null,
      request,
      Stream.renderEnabledStreams,
      showError,
      [freetext]
    );
  },

  renderEnabledStreams: (result, args) => {
    // If all condition are true then call setGlobalState function */

    const streams = result.data.response;
    const freetext = args[0];

    if (streams.length === 0) {
      if (freetext === null) {
        // If result is not present, render the NoEvent component
        const container = document.querySelector(".tab-content");
        const data = <NoStreamsAvailable />;
        CommonTemplate.ContainerAvailability(container, data);
      } else {
        const container = document.querySelector(".bxStreamList_box");
        const data = (
          <NoContent
            id={"noenablestream"}
            title={t("title.nostreamenabled")}
            description={t("description.nostreamfound")}
          />
        );
        CommonTemplate.ContainerAvailability(container, data);
        Stream.clearLayout();
      }
    } else {
      // If result is present, render the StreamListData component

      Stream.fetchAndAppendConfig(streams)
        .then((updatedStreams) => {
          const container = document.querySelector("#bnfxEnabledStreamList");
          const data = (
            <StreamListData
              streams={updatedStreams}
              enabled
              callback={() => {
                const firststream = document.querySelector(".stream-link");
                if (firststream) {
                  firststream.click();
                }
              }}
            />
          );
          CommonTemplate.ContainerAvailability(container, data);
        })
        .catch((error) => {
          console.error("Error updating streams:", error);
        });
    }
  },
  loadStreamComponent: (id) => {
    // Performs an AJAX POST request to fetch the INI configuration data for the tenant.
    return ajax(
      "post",
      requestURI["stream.content"],
      null,
      { _id: id },
      null,
      null,
      null,
      true
    );
  },
  fetchAndAppendConfig: async (streams) => {
    // Define a function to fetch the config for a single stream and append the result
    const fetchConfig = async (stream) => {
      try {
        // Call the API with the stream's _id
        const config = await Stream.loadStreamComponent(stream._id);
        // Append the result to the stream
        return config.data.response;
      } catch (error) {
        console.error(
          `Failed to fetch config for stream ${stream._id}:`,
          error
        );
      }
    };

    // Use Promise.all to fetch configs for all streams in parallel
    const updatedStreams = await Promise.all(
      streams.map((stream) => fetchConfig(stream))
    );

    return updatedStreams;
  },

  renderAvailableStreams: (result) => {
    const streams = result.data.response;
    const enabled = false;

    if (streams.length === 0) {
      // If result is not present, render the NoEvent component
      const container = document.querySelector("#bnfxAvailableStreamList");
      const data = (
        <NoContent
          id={"noavailablestream"}
          title={t("title.nostreamavailable")}
          description={t("description.nostreamfound")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      Stream.fetchAndAppendConfig(streams)
        .then((updatedStreams) => {
          const container = document.querySelector("#bnfxAvailableStreamList");
          const data = (
            <StreamListData streams={updatedStreams} enabled={enabled} />
          );
          CommonTemplate.ContainerAvailability(container, data);
        })
        .catch((error) => {
          console.error("Error updating streams:", error);
        });
    }
    //
  },

  getCount: (enabled, id) => {
    let freetext = CommonTemplate.getSearchText(id);

    //create the parameters object to get count of streams

    const parameters = {
      query: freetext ? freetext : undefined,
      enabled: enabled,
      type: "packages.streams",
    };

    enabled
      ? ajax(
          "post",
          requestURI["stream.count"],
          null,
          parameters,
          Stream.createEnabledPagination,
          showError
        )
      : ajax(
          "post",
          requestURI["stream.count"],
          null,
          parameters,
          Stream.createAvailablePagination,
          showError
        );
  },
  createEnabledPagination: (result) => {
    Stream.enabled_streams_total = null;
    const limit = Math.floor(
      Stream.adjustEnabledTabContentHeight() /
        (window.innerWidth > 1500 ? 71 : 51)
    );

    const total = result.data.response;
    Stream.enabled_streams_total = total;
    Stream.createPagination(total, limit, "bnfxEnabledPagination", true);
  },

  createAvailablePagination: (result) => {
    Stream.available_streams_total = null;
    let limit = Math.floor(
      Stream.adjustAvailableTabContentHeight() /
        (window.innerWidth > 1500 ? 71 : 51)
    );
    let total = result.data.response;
    Stream.available_streams_total = total;
    Stream.createPagination(total, limit, "bnfxAvailablePagination", false);
  },

  createPagination: (total, limit, id, enabled) => {
    const container = document.querySelector("#" + id);

    if (!container) {
      return;
    }

    const paginationData = {
      total: total,
      limit: limit,
      offset: 0,
    };

    const paginationComponent = enabled ? (
      <Pagination
        pagination={paginationData}
        onClickFn={Stream.listEnabledStreams}
      />
    ) : (
      <Pagination
        pagination={paginationData}
        onClickFn={Stream.listAvailableStreams}
      />
    );
    if (total > limit) {
      CommonTemplate.ContainerAvailability(container, paginationComponent);
    } else {
      const paginationComponent = null;
      CommonTemplate.ContainerAvailability(container, paginationComponent);
    }
    enabled
      ? Stream.setTotal(total, ".bnfxEnabledStreamTotal")
      : Stream.setTotal(total, "#bnfxAvailableStreamTotal");
  },

  setTotal: (total, id) => {
    var removeCollapse = document.getElementById("bnfxtoggle");
    if (id === ".bnfxEnabledStreamTotal") {
      if (total === 0) {
        removeCollapse.classList.add("d-none");
      } else {
        removeCollapse.classList.remove("d-none");
      }
    }

    if (id === "#bnfxAvailableStreamTotal" && total === 0) {
      document.getElementById("bxApplyStream").disabled = true;
    } else {
      document.getElementById("bxApplyStream").disabled = false;
    }

    const container = document.querySelectorAll(id);
    container.forEach((content) => {
      content.innerText = total;
    });
  },

  loadmoredatacoallapse: (ele) => {
    ele.parentElement.classList.toggle("rotate");
  },

  loadStream: (stream) => {
    Stream.clearStreamlayout();
    Stream.selectStream(stream);
    CommonTemplate.removeSaveSearchModal();
    const container = document.querySelector("#bnfxStreamDetailsLayout");

    const data = <StreamDetailsLayout stream={stream} />;
    CommonTemplate.ContainerAvailability(container, data);
  },

  copyDetection: (event, classname, notparent, json) => {
    let data;
    try {
      data = json
        ? JSON.stringify(json, null, 2)
        : notparent
        ? document.querySelector("." + classname).innerText
        : document.querySelector("." + classname).innerText;
    } catch (error) {
      console.error("Error retrieving data:", error);
      return;
    }

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          ToastPopup(t("copy.data.toast"));
        })
        .catch((err) => {
          console.error("Error copying data to clipboard:", err);
          ToastPopup(err);
        });
    } else {
      console.log("Clipboard API not supported, using fallback method");

      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = data;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand("copy");
        ToastPopup(t("copy.data.toast"));
      } catch (err) {
        console.error(
          "Error copying data to clipboard (fallback method):",
          err
        );
        ToastPopup(err);
      }

      // Clean up the temporary textarea element
      document.body.removeChild(textarea);
    }
  },

  getStatsData: (statObj, stattypes, stattypeindex) => {
    if (statObj.stattype === "DETECTIONS") {
      Stream.detectioncount = statObj.cumulative_sum;
    }
    let data = {};

    if (stattypes.indexOf(statObj.stattype) === -1) {
      return null;
    }
    data.index = stattypeindex[statObj.stattype];
    data.title = statObj.stattype;
    data.value = statObj.cumulative_sum;
    data.diff = statObj.diff;
    if (data.diff < 0) {
      data.icon = `material-symbols-outlined text-danger`;
      data.trend = "trending_down";
      data.diff = data.diff * -1;
    } else if (data.diff > 0) {
      data.icon = `material-symbols-outlined text-success`;
      data.trend = "trending_up";
    } else {
      data.icon = `material-symbols-outlined`;
      data.trend = "trending_flat";
    }

    stattypes.splice(stattypes.indexOf(statObj.stattype), 1);
    return data;
  },

  convertStats: (response, args) => {
    const result = response.data.response;

    let aggregationcount = args[1]?.aggregations?.count;

    if (aggregationcount === undefined) {
      aggregationcount = 0;
    }
    let correlationcount = args[1]?.correlation?.count;
    if (correlationcount === undefined) {
      correlationcount = 0;
    }
    let detectionscount = args[1]?.detections?.count;
    if (detectionscount === undefined) {
      detectionscount = 0;
    }
    if (
      result === undefined &&
      !(correlationcount > 0 && detectionscount === 0)
    ) {
      Stream.NoDetails();
    } else {
      const stattypes = [
        "FILTERED",
        "PUBLISHED",
        "DETECTIONS",
        "TRANSFORMED",
        "AUTOMATIONS",
        "CORRELATED",
        "AGGREGATED",
      ];

      const stattypeindex = {
        PUBLISHED: 0,
        FILTERED: 1,
        TRANSFORMED: 2,
        DETECTIONS: 3,
        AUTOMATIONS: 4,
        CORRELATED: 5,
        AGGREGATED: 6,
      };

      let chartData = [];
      for (let i = 0; i < result?.length; i++) {
        let data = Stream.getStatsData(result[i], stattypes, stattypeindex);

        if (data) {
          chartData.push(data);
        }
      }

      if (stattypes.length > 0) {
        while (stattypes.length > 0) {
          const stattype = stattypes[0];
          const statObj = { stattype: stattype, cumulative_sum: 0, diff: 0 };
          chartData.push(
            Stream.getStatsData(statObj, stattypes, stattypeindex)
          );
        }
      }

      if (correlationcount > 0 && detectionscount === 0) {
        chartData = chartData.filter(
          (data) =>
            data.title !== "DETECTIONS" &&
            data.title !== "AUTOMATIONS" &&
            data.title !== "AGGREGATED"
        );
      } else if (
        correlationcount === 0 &&
        detectionscount === 0 &&
        aggregationcount > 0
      ) {
        chartData = chartData.filter(
          (data) =>
            data.title !== "DETECTIONS" &&
            data.title !== "AUTOMATIONS" &&
            data.title !== "CORRELATED"
        );
      } else {
        chartData = chartData.filter(
          (data) => data.title !== "CORRELATED" && data.title !== "AGGREGATED"
        );
      }

      chartData.sort((a, b) => a.index - b.index);

      Stream.renderStats(chartData, args[1]);

      // stattypes.indexOf("DETECTIONS") === -1 => count of detection is >= 1
      if (
        correlationcount > 0 &&
        detectionscount === 0 &&
        aggregationcount === 0
      ) {
        Stream.setDetectionDataType("correlationdata");
        Stream.detectionSearchSuggest(args[0]);
        Artifact.getArtifactList(args[0]);
        Stream.getDetections(args[0]);
      } else if (
        correlationcount === 0 &&
        detectionscount === 0 &&
        aggregationcount > 0
      ) {
        Stream.setDetectionDataType("aggregationdata");
        Stream.detectionSearchSuggest(args[0]);
        Artifact.getArtifactList(args[0]);
        Stream.getDetections(args[0]);
      } else {
        if (stattypes.indexOf("DETECTIONS") === -1) {
          Stream.setDetectionDataType("detectiondata");
          Stream.detectionSearchSuggest(args[0]);
          Artifact.getArtifactList(args[0]);
          Stream.getDetections(args[0]);
          // detection count is 0 but corelation is present
        } else {
          Stream.setDetectionDataType("detectiondata");
          const element = document.getElementById(
            "bnfxCumulativeStatsStreamDetails"
          );
          if (element) {
            element.innerHTML = "";
          }
          const container = document.querySelector(
            "#bnfxDetectionEventStreamDetails"
          );

          const data = (
            <NoContent
              id={"nodetections"}
              title={t("title.nodetection")}
              description={t("description.nodetection")}
            />
          );

          CommonTemplate.ContainerAvailability(container, data);
        }
      }
    }
  },

  renderMetaData: (stream) => {
    const container = document.querySelector("#bnfxStreamMetaData");

    const data = <Metadata stream={stream} />;
    CommonTemplate.ContainerAvailability(container, data);
    //root.unmount();
  },

  renderStats: (chartData, stream) => {
    const container = document.querySelector(
      "#bnfxCumulativeStatsStreamDetails"
    );

    const data = <CumulativeStats chartData={chartData} id={stream._id} />;
    CommonTemplate.ContainerAvailability(container, data);
    Stream.getSummaryData(stream);

    Stream.getRawEvents(stream, "normalapicall");
    Stream.RawEventsTrendGraph(stream, false);

    //root.unmount();
  },

  getStats: (stream) => {
    CommonTemplate.renderLoadingWaveIcon("#bnfxCumulativeStatsStreamDetails");
    Artifact.renderArifactListError();
    const containersearcxh = document.querySelector("#bnfxloadSearch");
    let serachtype = null;

    CommonTemplate.ContainerAvailability(containersearcxh, serachtype);
    const container = document.querySelector(
      "#bnfxDetectionEventStreamDetails"
    );

    const data = null;

    CommonTemplate.ContainerAvailability(container, data);

    let correlationcount = stream?.correlation?.count;

    if (correlationcount === undefined) {
      correlationcount = 0;
    }
    let detectionscount = stream?.detections?.count;
    if (detectionscount === undefined) {
      detectionscount = 0;
    }
    let parameters = null;

    if (correlationcount > 0 && detectionscount === 0) {
      parameters = {
        objectid: stream._id,
        limit: 5,
        offset: 0,
        report: false,
      };
    } else {
      parameters = {
        objectid: stream._id,
        limit: 5,
        offset: 0,
        report: false,
        timeDays: Stream.selected_days,
        timePhrase: "Last " + Stream.selected_days + " Days",
      };
    }

    ajax(
      "post",
      requestURI["stream.stats"],
      null,
      parameters,
      Stream.convertStats,
      showError,
      [stream._id, stream]
    );
  },

  NoDetails: () => {
    const detectionelement = document.getElementById(
      "bnfxDetectionEventStreamDetails"
    );
    const searchelement = document.getElementById("bnfxloadSearch");
    if (detectionelement || searchelement) {
      detectionelement.innerHTML = "";
      searchelement.innerHTML = "";
    }

    const container = document.querySelector(
      "#bnfxCumulativeStatsStreamDetails"
    );

    const data = (
      <div className="row mt-1  bxRightCardTop g-2 ">
        <div className="col">
          <NoStatCard />
        </div>
        <div className="col">
          <NoStatCard />
        </div>
        <div className="col">
          <NoStatCard />
        </div>
        <div className="col">
          <NoStatCard />
        </div>
        <div className="col">
          <NoStatCard />
        </div>
      </div>
    );

    CommonTemplate.ContainerAvailability(container, data);
    Stream.removeTimelineTab();
    return null;
  },

  removeTimelineTab: () => {
    const tabTimeline = document.querySelector(".tab-content.tabTimeline");
    if (tabTimeline) {
      tabTimeline.classList.add("d-none");
    }

    const tab_timeline = document.querySelector(".tab_timeline");
    if (tab_timeline) {
      tab_timeline.classList.add("d-none");
    }
  },

  showTimelineTab: () => {
    const tabTimeline = document.querySelector(".tab-content.tabTimeline");
    if (tabTimeline) {
      tabTimeline.classList.remove("d-none");
    }

    const tab_timeline = document.querySelector(".tab_timeline");
    if (tab_timeline) {
      tab_timeline.classList.remove("d-none");
    }
  },

  convertDetections: (response, args) => {
    const result = response.data.response.result;
    const status = response.data.response.status;
    let total = response.data.response.total;
    const container = document.querySelector(
      "#bnfxDetectionEventStreamDetails"
    );
    let data = null;
    if (status === "failed") {
      data = (
        <NoContent
          id={"nodetections"}
          title={t("title.nodetectionfound")}
          description={t("description.nodetectionfound")}
        />
      );
      const paginationcontainer = document.querySelector(
        "#bnfxPaginationEventStreamDetails"
      );

      const paginationdata = null;
      CommonTemplate.ContainerAvailability(paginationcontainer, paginationdata);
    } else {
      Stream.showTimelineTab();
      const offset = args[0];

      if (total) {
        Stream.setDetectionTotal(total);
      }

      total = Stream.detection_total;

      data = (
        <DetectionEvent
          detectionData={result}
          id={args[1]}
          querysearchtext={args[2]}
          callback={() => Stream.renderPagination(total, offset)}
          userInfo={Stream.detectionDataType}
          streamdetails={Stream.selected_stream}
        />
      );
    }
    CommonTemplate.ContainerAvailability(container, data);
  },
  renderPagination: (total, offset) => {
    if (offset > 0) {
      return;
    }
    const paginationData = {
      total: total,
      limit: 10,
      offset: offset ? offset : 0,
    };

    const container = document.querySelector(
      "#bnfxPaginationEventStreamDetails"
    );
    const data = (
      <Pagination
        pagination={paginationData}
        onClickFn={Stream.getPaginationDetections}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },

  getPaginationDetections: (offset) => {
    Stream.getDetections(Stream.selected_stream?._id, offset);
  },

  detectionSearchSuggest: (streamid) => {
    ajax(
      "get",
      requestURI["list.columns"],
      null,
      null,
      Stream.loadSearchLayout,
      showError,
      [streamid]
    );
  },
  loadSearchLayout: (response, args) => {
    const container = document.querySelector("#bnfxloadSearch");
    let serachtype = null;
    if (Stream.detectionDataType === "correlationdata") {
      serachtype = "user";
    } else {
      serachtype = "detection";
    }
    const data = (
      <LoadDetectionSearch
        autosuggestions={response.data.response}
        streamid={args[0]}
        serachtype={serachtype}
        detectionDataType={Stream.detectionDataType}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },
  setDetectionDataType: (detectiontypedata) => {
    Stream.detectionDataType = detectiontypedata;
  },

  modifyEscapeCharacters: (sql) => {
    let modifiedSql = sql?.replace(/%/g, "%%");
    return modifiedSql;
  },
  serachresultonlclick: (streamid, type) => {
    CommonTemplate.removeSaveSearchModal();
    const freetext =
      Stream.detectionSql === undefined
        ? ""
        : Stream.modifyEscapeCharacters(Stream.detectionSql);
    Stream.setDetectionfreetext(freetext);
    if (type === "rawevents") {
      Stream.getRawEvents(Stream.selected_stream, "normalapicall");
      Stream.RawEventsTrendGraph(Stream.selected_stream, false);
    } else {
      Stream.getDetections(streamid);
      Artifact.getArtifactList(streamid);
    }
  },
  executesavedearchquery: (streamid, query) => {
    const freetext = Stream.modifyEscapeCharacters(query);
    Stream.setDetectionfreetext(freetext);
    Stream.getDetections(streamid);
    Artifact.getArtifactList(streamid);
  },
  setDetectionfreetext: (freetext) => {
    Stream.selected_freetext = freetext;
  },
  getDetections: (streamid, offset) => {
    CommonTemplate.renderLoadingIcon("#bnfxDetectionEventStreamDetails");

    if (offset === undefined) {
      offset = 0;
    }
    const parameters = {
      streamid: streamid,
      limit:
        Stream.detectioncount > 10 || Stream.detectioncount === 0
          ? 10
          : Stream.detectioncount,
      query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
      offset: offset,
      report: false,
      sortcol:
        Stream.detectionDataType === "aggregationdata"
          ? "timestamp"
          : "detectiontime",
      sortorder: "desc",
      timeDays: Stream.selected_days,
      flag:
        Stream.detectionDataType === "correlationdata"
          ? "userInfo"
          : Stream.detectionDataType === "aggregationdata"
          ? "aggregation"
          : "detectiondata", // Ensure this handles the other cases
      timePhrase: "Last " + Stream.selected_days + " Days",
    };

    ajax(
      "post",
      requestURI["stream.detections"],
      null,
      parameters,
      Stream.convertDetections,
      showError,
      [
        offset,
        streamid,
        Stream.selected_freetext ? Stream.selected_freetext : undefined,
      ]
    );
  },

  loadActionDetails: (stream, enabled) => {
    const container = document.querySelector("#bnfxActionList");
    container.setAttribute("data-current-container", "stream");
    const data = <ActionList stream={stream} enabled={enabled} />;
    CommonTemplate.ContainerAvailability(container, data);
    // const parameters = {
    //   _id: stream._id,
    // };

    // ajax(
    //   "post",
    //   requestURI["stream.content"],
    //   null,
    //   parameters,
    //   Stream.renderViewpage,
    //   showError,
    //   [enabled]
    // );
  },
  // renderViewpage: (response, args) => {
  //   let enabled = args[0];
  //   let result = response.data.response;

  // },

  extractDeleteData: () => {
    return {
      tenant: Stream.selected_stream?.tenant,
      id: Stream.selected_stream?._id,
      type: "streams",
    };
  },

  deleteStream: () => {
    const payload = Stream.extractDeleteData();
    const withLayout = Stream.enabled_streams_total === 1 ? true : false;

    ajax(
      "post",
      requestURI["disable.content"],
      null,
      payload,
      Stream.streamdeletedsuccesfulrender,
      ShowErrorToastPopup,
      [withLayout]
    );
  },
  streamdeletedsuccesfulrender: (result, args) => {
    const deletetrue = t("Deleted");
    const streamdelete = t("stream.succesfull.delete.toast");
    let success = t("success");
    Swal.fire(deletetrue, streamdelete, success);
    Stream.viewEnabledStreams(result, args);
  },

  extractAddData: () => {
    if (Stream.available_stream !== null) {
      return {
        tenant: Stream.available_stream.tenant,
        id: Stream.available_stream._id,
        type: "streams",
      };
    } else {
      showError(t("no.stream.message"));

      return null; // Return null when no stream is available
    }
  },

  extractStreams: (withlayout) => {
    const payload = Stream.extractAddData();

    if (payload) {
      // Check if the new id is different from the previous id

      ToastPopup(t("enable.stream.toast"));

      ajax(
        "post",
        requestURI["enable.content"],
        null,
        payload,
        Stream.viewEnabledStreams,
        ShowErrorToastPopup,
        [withlayout]
      );
    } else {
      return null;
    }
  },

  addStream: () => {
    /*It render StreamListLayout component,passing streams & append to the container element*/
    if (
      Stream.enabled_streams_total === null ||
      Stream.enabled_streams_total === 0
    ) {
      Stream.extractStreams(true);
    } else {
      Stream.extractStreams(false);
    }
  },

  viewEnabledStreams: (result, args) => {
    document.getElementById("available_stream_search").value = "";
    setTimeout(() => {
      if (args[0]) {
        const container = document.querySelector(".tab-content");
        const data = <StreamsWrapperContainer />;
        CommonTemplate.ContainerAvailability(container, data);
      } else {
        Stream.clearLayout();
        Stream.loadEnabledLayout(0);
      }
    }, 500);
  },

  deleteConfirmation: (title) => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation") + title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        Stream.deleteStream();
      }
    });
  },

  bxCommonHeights: () => {
    let footer = document.querySelector("#bxFooter");
    let header = document.querySelector("#bxNavBar");

    var bxFooter_height = footer?.clientHeight || 0;

    var bxHeader_height = header?.clientHeight || 0;

    var windowHeight = window.innerHeight;
    var bxEnabledTotal_height =
      parseInt(bxHeader_height) + parseInt(bxFooter_height);
    var bxTabsHeight = parseInt(windowHeight) - parseInt(bxEnabledTotal_height);

    return bxTabsHeight;
  },

  adjustEnabledTabContentHeight: () => {
    var bxTabsHeight = Stream.bxCommonHeights();

    if (!document.querySelector(".bxStreamLeftSideBar_searchArea")) {
      return;
    }

    var bxTopSearch = document.querySelector(
      ".bxStreamLeftSideBar_searchArea"
    ).clientHeight;

    var bxListEnabledHeight = parseInt(bxTabsHeight) - parseInt(bxTopSearch);

    document.querySelector(".bxStreamLeftSideBar").style.minHeight =
      bxTabsHeight + "px";
    document.querySelector(".bxStreamList_box").style.minHeight =
      bxListEnabledHeight + "px";
    document.querySelector(".fullwidth").style.minHeight = bxTabsHeight + "px";

    return bxListEnabledHeight;
  },

  adjustAvailableTabContentHeight: () => {
    var bxAvailableTopSearch = document.querySelector(
      ".bxSuggestionTopBar"
    ).clientHeight;
    var bxAvailableFooter =
      document.querySelector(".offcanvas-footer").clientHeight;

    var bnfxAvailableWindowHeight = document.querySelector(
      ".bxAddStreamOffCanvas"
    ).clientHeight;
    var bnfxAvailableHeaderHeight =
      document.querySelector(".offcanvas-header").clientHeight;
    var bnfxAvailableStreamCount =
      document.querySelector(".bxSuggestionArea").clientHeight;
    var bxAvailableTotal_height =
      parseInt(bxAvailableFooter) + parseInt(bnfxAvailableHeaderHeight);
    var bxListAvailableTabsHeight =
      parseInt(bnfxAvailableWindowHeight) - parseInt(bxAvailableTotal_height);
    var bxListAvailableHeight =
      parseInt(bxListAvailableTabsHeight) -
      (parseInt(bxAvailableTopSearch) + parseInt(bnfxAvailableStreamCount));

    document.querySelector(".offcanvas-body").style.minHeight =
      bxListAvailableTabsHeight + "px";

    return bxListAvailableHeight;
  },

  clearLayout: () => {
    Stream.selected_stream = null;
    const container = document.querySelector("#bnfxStreamDetailsLayout");
    container.innerHTML = "";
  },
  loadStreamrowOnclick: (data, clicktype) => {
    let detectiondata = null;
    if (clicktype === "entity") {
      detectiondata = {
        entity: "" + data.entity,

        entitytype: data.entitytype,
        // risk_score: data.score,   removed because of unused
        type: "entity",
        streamid: data.streamid,
        tenant: data.tenant,
      };
    } else {
      detectiondata = {
        detectionid: data.detectionid,
        name: data.name ? data.name : "no title",
        type: "detection",
        streamid: data.streamid,
        tenant: data.tenant,
      };
    }
    const endtime = moment();
    const starttime =
      Stream.selected_days === 1
        ? moment().startOf("day")
        : moment().subtract(Stream.selected_days - 1, "days");
    const stime = Stream.daystimeconversion(starttime, true);
    const etime = Stream.daystimeconversion(endtime, false);
    const timephrase = "Last " + Stream.selected_days + " Days";

    PanelScript.loadrowOnclick(detectiondata, stime, etime, timephrase);
  },
  daystimeconversion: (time, startime) => {
    const localTime = time.toDate();
    const year = localTime.getFullYear();
    const month = (localTime.getMonth() + 1).toString().padStart(2, "0");
    const day = localTime.getDate().toString().padStart(2, "0");
    let hours = null;
    let minutes = null;
    let ampm = null;
    if (startime) {
      hours = "00";
      minutes = "00";
      ampm = "AM";
    } else {
      hours = "23";
      minutes = "59";
      ampm = "PM";
    }

    const finalTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    return finalTime;
  },
  loadattackpatterngraph: (
    detectionname,
    detectionid,
    entity,
    entitytype,
    tenant,
    score,
    streamid
  ) => {
    setGlobalState("ispanelrequired", false);
    const endtime = moment();
    const starttime =
      Stream.selected_days === 1
        ? moment().startOf("day")
        : moment().subtract(Stream.selected_days - 1, "days");
    const stime = CommonTemplate.timeconversion(starttime);
    const etime = CommonTemplate.timeconversion(endtime);
    PanelScript.setDefaultStartDate(stime);
    PanelScript.setDefaultEndDate(etime);
    PanelScript.handleGraphTabClick(
      detectionname,
      detectionid,
      entity,
      entitytype,
      tenant,
      score,
      streamid
    );
  },
  getTenantsList: () => {
    ajax(
      "get",
      requestURI["list.tenant"],
      null,
      null,
      Stream.renderTenantList,
      showError
    );
  },

  renderTenantList: (response) => {
    let tenantlist = response.data.response;

    const tenantList = tenantlist.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    const container = document.getElementById("bnfxtenantlist");
    let tenantdata = (
      <SettingTenantLayout
        data={tenantList}
        tenantdatacount={Stream.retriveddata}
        callback={() => {
          const firsttenant = document?.querySelector(".settings-link");
          if (firsttenant) {
            firsttenant.click();
          }
        }}
      />
    );
    CommonTemplate.ContainerAvailability(container, tenantdata);
  },

  loadStreamSettingTenantList: (stream) => {
    let data = document.getElementById("bnfxtenantlist");
    data.innerHTML = "";
    const userdetails = jwt(token);

    let tenantcount = userdetails?.tenants?.length;

    if (
      tenantcount === null ||
      tenantcount === 0 ||
      tenantcount === undefined
    ) {
      Stream.getTenantsList();
    } else {
      let tokentenantlist = userdetails.tenants;
      const tenantList = tokentenantlist.map((item) => ({
        value: item,
        label: item,
      }));
      const container = document.getElementById("bnfxtenantlist");
      let tenantdata = (
        <SettingTenantLayout
          data={tenantList}
          tenantdatacount={Stream.retriveddata}
          callback={() => {
            const firsttenant = document?.querySelector(".settings-link");
            if (firsttenant) {
              firsttenant.click();
            }
          }}
        />
      );
      CommonTemplate.ContainerAvailability(container, tenantdata);
    }
  },
  selectTenantChange: (selectedTenant) => {
    Stream.selectedTenant = selectedTenant;
    Stream.ontenantclickfetech(selectedTenant);
  },

  getParamtersList: () => {
    let data = {
      streamid: Stream.selected_stream._id,
    };
    ajax(
      "post",
      requestURI["list.parameters"],
      null,
      data,
      Stream.renderParameterList,
      showError
    );
  },
  setparamlist: (paramlist) => {
    Stream.selected_param = paramlist;
  },
  renderParameterList: (response) => {
    let ParamList = response.data.response;
    Stream.setparamlist(ParamList);
    let bnfxcontainer = document.querySelector("#bnfxrequiredparams");
    let tenantdropdown = ParamList.map((item, index) =>
      !item.startsWith("http") ? (
        <div className="col-sm-12" key={index}>
          <div className="mb-2">
            <label htmlFor={item} className="form-label">
              {item}
            </label>
            <input
              type="text"
              className="form-control"
              id={item}
              placeholder={`Please enter ${item}`}
              autoComplete="off"
              required
            />
            <small className="d-block text-end bxFontSize">
              {t("required")}
            </small>
          </div>
        </div>
      ) : null
    );

    let footermenu = (
      <div className="offcanvas-footer ">
        <div className="d-flex align-items-center justify-content-end">
          <button
            type="button"
            id="bnfxsettingdelete"
            className="deletemeta me-3"
            data-bs-dismiss="offcanvas"
            onClick={Stream.deleteSettingConfirmation}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            onClick={Stream.handlesettingsubmit}
          >
            {t("name.save")}
          </button>
        </div>
      </div>
    );
    let data = (
      <div>
        {tenantdropdown}
        {footermenu}
      </div>
    );

    CommonTemplate.ContainerAvailability(bnfxcontainer, data);
    Stream.loadStreamSettingTenantList();
  },
  deleteSettingConfirmation: () => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        Stream.deletesetting();
      }
    });
  },
  deletesetting: () => {
    let saveddata = Stream.retriveddata[0];
    ajax(
      "delete",
      requestURI["delete.setting"] + "/" + saveddata._id,
      null,
      null,
      Stream.rendertsettingdelete,
      showError
    );
  },
  rendertsettingdelete: () => {
    ToastPopup("Setting Deleted Succesfully");
    Stream.LoadSavedSettingsList(Stream.selected_stream);
  },
  handlesettingsubmit: (event) => {
    const keyValuePair = Stream.selected_param?.reduce((result, item) => {
      const element = document.getElementById(item);
      if (element) {
        result[item] = element.value;
      }

      return result;
    }, {});
    if (Stream.issettingeditable) {
      Stream.updatesettings(keyValuePair);
    } else {
      Stream.savesettings(keyValuePair);
    }
  },
  updatesettings: (keyValuePair) => {
    let data = Stream.retriveddata[0];
    let requestheader = {
      configuration: keyValuePair,
      tenant: data.tenant,
      streamid: data.streamid,
    };

    ajax(
      "post",
      requestURI["update.savedsettings"] + "/" + data._id,
      null,
      requestheader,
      Stream.renderUpdatedSetting,
      showError
    );
  },
  renderUpdatedSetting: () => {
    ToastPopup("Setting Successfully updated");
    Stream.LoadSavedSettingsList(Stream.selected_stream);
  },
  savesettings: (data) => {
    let updateddata = Stream.selected_param;
    updateddata.map((item, index) =>
      item.startsWith("http") ? (data["url"] = item) : null
    );

    let requestheader = {
      configuration: data,
      tenant: Stream.selectedTenant,
      streamid: Stream.selected_stream._id,
    };
    ajax(
      "post",
      requestURI["savesetting"],
      null,
      requestheader,
      Stream.renderSavedSetting,
      showError
    );
  },
  renderSavedSetting: () => {
    ToastPopup("Setting Added Succesfully");
    Stream.LoadSavedSettingsList(Stream.selected_stream);
  },

  LoadSavedSettingsList: (stream) => {
    const container = document.getElementById("bnfxtenantlist");
    container.innerHTML = "";
    Stream.setretriveddata(null);
    const conditionarr = [
      {
        column: "streamid",
        value: stream._id,
        condition: "eq",
      },
    ];
    let requestpayload = { conditions: conditionarr };

    ajax(
      "post",
      requestURI["list.saved.settings"],
      null,
      requestpayload,
      Stream.renderListSavedSetting,
      showError,
      [stream]
    );
  },
  getSavedSettingCount: (id) => {
    const conditionarr = [
      {
        column: "streamid",
        value: id,
        condition: "eq",
      },
    ];
    let requestpayload = { conditions: conditionarr };

    ajax(
      "post",
      requestURI["total.organization"] + "/withbody/streamsettings",
      null,
      requestpayload,
      Stream.renderCountSavedSetting,
      showError
    );
  },
  renderCountSavedSetting: (response) => {
    let total = response.data.response;
    let conatiner = document.getElementById("bnfxsettingicon");
    let settingicon = null;
    if (total === 0) {
      // let settingicon = document.getElementById("bnfxstreamsettings")
      // settingicon.classList?.add("nosettingsdata")
      settingicon = (
        <span
          className="material-symbols-outlined"
          title="Please Configure Settings"
        >
          <i className="text-warning">!</i>manage_accounts
        </span>
      );
      // NotificationToastPopup("Please configure the settings!");
    } else {
      // let settingicon = document.getElementById("bnfxstreamsettings")
      // settingicon.classList?.remove("nosettingsdata")
      settingicon = (
        <span className="material-symbols-outlined" title="Settings">
          manage_accounts
        </span>
      );
    }
    CommonTemplate.ContainerAvailability(conatiner, settingicon);
  },
  setissettingeditable: (isedit) => {
    Stream.issettingeditable = isedit;
  },
  setretriveddata: (data) => {
    Stream.retriveddata = data;
  },

  renderListSavedSetting: (response, args) => {
    let SavedSettinglist = response.data.response;

    if (SavedSettinglist.length !== 0) {
      Stream.setretriveddata(SavedSettinglist);
    } else {
      console.log(SavedSettinglist);
    }
    Stream.getParamtersList();
  },
  ontenantclickfetech: (tenant) => {
    let deletebutton = document.getElementById("bnfxsettingdelete");
    deletebutton?.classList?.remove("d-none");
    Stream.selected_param.map((item) => {
      const element = document.getElementById(item);
      if (element) {
        element.value = "";
      }
      return null;
    });
    Stream.setissettingeditable(false);
    let data = Stream.retriveddata;
    const matchingRecord = data?.find((record) => record.tenant === tenant);

    if (matchingRecord) {
      Stream.setissettingeditable(true);
      let params = Stream.selected_param;
      let parameterdata = matchingRecord.configuration;

      params.map((item) => {
        const element = document.getElementById(item);
        if (element) {
          element.value = parameterdata[item];
        }
        return null;
      });
    } else {
      deletebutton.classList.add("d-none");
      console.log("No matching record found.");
    }
  },
  handleDoubleClick: (key, value) => {
    let detectionSql = null;

    if (Stream.detectionDataType === "correlationdata") {
      detectionSql = `(e.${key} in ['${value}'] or (identity.label in ['${key}'] AND identity.value in ['${value}']))`;
    } else {
      if (key.includes("set")) {
        const keyParts = key.split(".");
        keyParts.pop();

        detectionSql = `${keyParts.join(".")} @> '["${value}"]'::jsonb`;
      } else {
        detectionSql = `${key} in ('${value}')`;
      }
    }
    Stream.detectionSql = detectionSql;
    setGlobalState("bnfx_global_stream_detction_serachbar", detectionSql);
  },
  badgeriskcolour: (criticality) => {
    return criticality === "HIGH"
      ? "highColor"
      : criticality === "MEDIUM"
      ? "mediumColor"
      : criticality === "LOW"
      ? "lowColor"
      : "criticalityColor";
  },
  getDownloadDetections: () => {
    const parameters = {
      streamid: Stream.selected_stream._id,
      limit: 10,
      query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
      offset: 0,
      report: false,
      sortcol: "detectiontime",
      sortorder: "desc",
      timeDays: Stream.selected_days,
      userInfo: Stream.detectionDataType,
      download: true,
      fileprefix: document.querySelector("#prefixInput").value,
      timePhrase: "Last " + Stream.selected_days + " Days",
    };

    ajax(
      "post",
      requestURI["stream.detections"],
      null,
      parameters,
      Stream.renderDownloadeddetection,
      Stream.renderDownloaddetecionerror
    );
  },
  renderDownloadeddetection: (result) => {
    let path = result.data.response.result.path;
    let refinepath = result.data.response.result.path;
    const parts = refinepath.split("/");
    const filename = parts.pop(); // Extract the last part as filename
    const directory = parts.pop(); // Rejoin the remaining parts as directory
    path = `<span class="p-2 text-white">Download <p class="textPrimary">${filename}</p> </span><small>You could also download later from available exports from header</small> <button class="textPrimary" data-bs-dismiss="modal">click here</button>`;
    let conatiner = document.querySelector("#bnfxdownloadedpath");
    let closeconnection = document.querySelector("#bnfxprefixform");
    closeconnection?.classList?.add("d-none");
    let newconnection = document.querySelector(".downloadresult");
    newconnection?.classList?.add("d-none");
    conatiner.innerHTML = path;
    const clickHandler = () => {
      DownloadsScript.downloadapicall(directory, filename);
      conatiner.removeEventListener("click", clickHandler);
      conatiner.innerHTML = "";
    };
    conatiner.addEventListener("click", clickHandler);
  },

  renderDownloaddetecionerror: (result) => {
    ShowErrorToastPopup(t("message.nodata"));
  },

  openTab: (evt, tabName) => {
    var i, tabcontent, tab_summary, tab_timeline, tab_rawevents;
    tabcontent = document.getElementsByClassName("tab-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("active");
    }
    tab_summary = document.getElementsByClassName("tab_summary");
    for (i = 0; i < tab_summary.length; i++) {
      tab_summary[i].classList.remove("active");
    }

    tab_timeline = document.getElementsByClassName("tab_timeline");
    for (i = 0; i < tab_timeline.length; i++) {
      tab_timeline[i].classList.remove("active");
    }
    tab_rawevents = document.getElementsByClassName("tab_rawevents");
    for (i = 0; i < tab_rawevents.length; i++) {
      tab_rawevents[i].classList.remove("active");
    }
    document.getElementById(tabName).classList.add("active");
    evt.currentTarget.classList.add("active");

    Stream.showDownloadBtn();

    if (tabName === "tabRawevents") {
      setGlobalState("rawEventsTabIsActive", true);
    } else {
      setGlobalState("rawEventsTabIsActive", false);
    }
  },

  showDownloadBtn: () => {
    const tabSummary = document.querySelector(".tab-content.tabSummary.active");
    if (tabSummary) {
      const removenone = document.querySelector(".summaryDownload_Btn");
      removenone?.classList?.remove("d-none");
    }

    const tabTimeline = document.querySelector(
      ".tab-content.tabTimeline.active"
    );
    if (tabTimeline) {
      const shownone = document.querySelector(".summaryDownload_Btn");
      shownone?.classList?.add("d-none");
    }
  },

  getSummaryData: (stream) => {
    CommonTemplate.renderLoadingIcon("#bnfxTotalDetectionSummary");
    const request = {
      tenant: stream.tenant,
      streamid: stream._id,
    };

    ajax(
      "post",
      requestURI["streams.summmary"],
      null,
      request,
      Stream.renderSummaryData,
      showError,
      [stream]
    );
  },

  renderSummaryData: (response, args) => {
    let output = response.data.response;
    let status = response.data.status;
    let detectionDetails = response?.data?.response?.results?.Details;

    let streamname = args[0].title;
    if (streamname === "Employee Directory") {
      Stream.applyClass();
    }

    if (detectionDetails?.length === 0) {
      const container = document.querySelector("#bnfxDetectionEntities");
      const data = <NoDetections />;
      CommonTemplate.ContainerAvailability(container, data);
    }
    if (status === "FAILED" || status === "failed") {
      let container = document.querySelector("#bnfxTotalDetectionSummary");
      let data = null;
      CommonTemplate.ContainerAvailability(container, data);
      const removesummarytab = document.querySelector(".tab_summary.active");
      const tab_timeline = document.querySelector(".tab_timeline");
      const tabTimeline = document.querySelector(".tab-content.tabTimeline");

      if (removesummarytab || tab_timeline || tabTimeline) {
        tab_timeline?.classList.add("active");
        tabTimeline?.classList.add("active");
        removesummarytab?.classList.add("d-none");
      }
    } else {
      const container = document.querySelector("#bnfxTotalDetectionSummary");
      const data = <DetectionList output={output} stream={args[0]} />;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  applyClass: () => {
    const tabSummary = document.querySelector(".tab-content.tabSummary.active");
    if (tabSummary) {
      tabSummary.classList.remove("active");
    }

    const tab_summary = document.querySelector(".tab_summary.active");
    if (tab_summary) {
      tab_summary.classList.remove("active");
    }

    const tabTimeline = document.querySelector(".tab-content.tabTimeline");
    if (tabTimeline) {
      tabTimeline.classList.add("active");
    }

    const tab_timeline = document.querySelector(".tab_timeline");
    if (tab_timeline) {
      tab_timeline.classList.add("active");
    }

    const tab_summary_show_none = document.querySelector(".tab_summary");
    if (tab_summary_show_none) {
      tab_summary_show_none.classList.add("d-none");
    }

    const show_none = document.querySelector(".summaryDownload_Btn");
    if (show_none) {
      show_none.classList.add("d-none");
    }
  },

  loadMoreDataCoallapse: (ele) => {
    const button = ele;
    const span = button.querySelector(".showmore");

    if (button.textContent.includes("SHOW MORE")) {
      button.firstChild.textContent = "SHOW LESS";
      span.textContent = "keyboard_double_arrow_up";
    } else {
      button.firstChild.textContent = "SHOW MORE";
      span.textContent = "keyboard_double_arrow_down";
    }

    button.parentElement.classList.toggle("rotate");
  },
  getStellarChart: () => {
    const request = {};

    ajax(
      "post",
      requestURI["streams.summmary"],
      null,
      request,
      Stream.renderStellarChartData,
      showError
    );
  },
  renderStellarChartData: (response) => {
    let series = response;
    const container = document.querySelector("#bnfxStellarChart");
    const data = <StellarChart series={series} conatiner="bnfxStellarChart" />;
    CommonTemplate.ContainerAvailability(container, data);
  },
  redirectsummary: (criticalitystat, detectionstat, detectiontrend) => {
    var Tabs_streams_Time_Summary_btns = document.querySelector(
      ".Tabs_streams_Time_Summary_btns"
    )?.clientHeight;
    var bxSREDetails = document.querySelector("#bxSREDetails").clientHeight;
    var bxFooter_height = document.querySelector("#bxFooter").clientHeight;
    var windowHeight = window.innerHeight;
    var summaryheight =
      parseInt(windowHeight) -
      (parseInt(Tabs_streams_Time_Summary_btns) +
        parseInt(bxSREDetails) +
        parseInt(bxFooter_height) +
        75);

    var summaryLeftElement = document.querySelector(".llm_summary_left");
    if (summaryLeftElement) {
      summaryLeftElement.style.minHeight = summaryheight - 20 + "px";
    }

    const container_llm_summary_detection_trend = document.querySelector(
      ".llm_summary_detection_trend"
    );
    const container_llm_summary_criticality_stat = document.querySelector(
      ".llm_summary_criticality_stat"
    );
    const container_llm_summary_detection_stat = document.querySelector(
      ".llm_summary_detection_stat"
    );

    const keys = Object.keys(detectionstat);
    const values = Object.values(detectionstat);

    // Example usage
    let newdetectionstatseries = [{ name: "", data: values }];
    let newdetectionstatcategories = keys;

    let detection_stat_data = (
      <>
        <span className="summarybarchart">ENTITY COUNT BY DETECTION</span>
        <BarChart
          series={newdetectionstatseries}
          categories={newdetectionstatcategories}
          container={"llm_summary_detection_stat"}
          height={140}
        />
      </>
    );
    CommonTemplate.ContainerAvailability(
      container_llm_summary_detection_stat,
      detection_stat_data
    );
    let criticalitystat_data = (
      <>
        <span className="summarybarchart">
          DETECTIONS COUNTS BY CRITICALITY
        </span>
        <DonutChart data={criticalitystat} height={140} />
      </>
    );
    CommonTemplate.ContainerAvailability(
      container_llm_summary_criticality_stat,
      criticalitystat_data
    );

    const fillMissingHours = (data) => {
      const newData = {};

      // Extract the date from the first timestamp in data
      const firstTimestamp = Object.keys(data)[0];
      const date = new Date(parseInt(firstTimestamp));
      const day = ("0" + date.getUTCDate()).slice(-2);

      // Loop through each timestamp in original data
      Object.keys(data).forEach((timestamp) => {
        const date = new Date(parseInt(timestamp));
        const hour = ("0" + date.getUTCHours()).slice(-2);

        // Create key pattern for each hour of the day
        const formattedDate = `${day}:${hour}:00`;

        // Initialize with zero if key doesn't exist
        if (!(formattedDate in newData)) {
          newData[formattedDate] = 0;
        }

        // Add original data entry to corresponding hour
        newData[formattedDate] += data[timestamp];
      });

      // Fill missing hours with zero if no data exists
      for (let hour = 0; hour < 24; hour++) {
        const formattedDate = `${day}:${("0" + hour).slice(-2)}:00`;
        if (!(formattedDate in newData)) {
          newData[formattedDate] = 0;
        }
      }

      return newData;
    };

    const newData = fillMissingHours(detectiontrend);
    const newkeys = Object.keys(newData).sort();
    const newvalues = newkeys.map((key) => newData[key]);

    let detectiontrend_data = (
      <>
        <span className="summarybarchart">DETECTIONS TRENDS BY HOUR</span>
        <TrendChart
          series={newvalues}
          categories={newkeys}
          container={"llm_summary_detection_trend"}
          height={140}
          tickInterval={6}
        />
      </>
    );
    CommonTemplate.ContainerAvailability(
      container_llm_summary_detection_trend,
      detectiontrend_data
    );
  },

  convertTimestamp: (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = "00"; // Always set minutes to 00
    return `${day}:${hours}:${minutes}`;
  },
  
  getRawEvents: (stream, mode) => {
    if (Stream.raweventQueryid) {
      Stream.deletequerid();
    }
    if (mode !== "livemode") {
      Stream.streamRawEventsSearchSuggest(Stream.selected_stream);
    }
    CommonTemplate.renderLoadingIcon("#bnfxStreamRawdata");
    let request = null;
    if (mode === "normalapicall") {
      request = {
        streamid: stream._id,
        limit: 10,
        offset: 0,
        query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
        sortcol: "timestamp",
        sortorder: "desc",
      };
    } else if (mode === "livemode") {
      request = {
        streamid: stream._id,
        limit: 10,
        offset: 0,
        query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
        sortcol: "timestamp",
        sortorder: "desc",
        livemode: true,
      };
    } else if (mode === "rangeselector") {
      request = {
        streamid: stream._id,
        limit: 10,
        offset: 0,
        query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
        sortcol: "timestamp",
        sortorder: "desc",
        startime: Stream.raweventRangeSelector[0],
        endtime: Stream.raweventRangeSelector[1],
      };
    }
    ajax(
      "post",
      requestURI["stream.rawevents"],
      null,
      request,
      Stream.renderRawevents,
      showError,
      [mode]
    );
  },
  renderRawevents: (response, args) => {
    const queryid = response.data.response.queryid;
    Stream.raweventQueryid = queryid;
    let mode = args[0];
    if (Stream.rawEventsLiveModeInterval) {
      clearInterval(Stream.rawEventsLiveModeInterval);
    }
    Stream.fetchQueryResultWithRetry(mode);
  },
  fetchQueryResultWithRetry: (mode) => {
    let count = 0; // Initialize count
    const maxAttempts = 10; // Maximum number of attempts
    const intervalId = setInterval(async () => {
      // Check if the raweventQueryid is available
      if (Stream.raweventQueryid) {
        // Call the function to fetch results
        const response = await Stream.fetechqueridresult(0); // Calculate the offset

        if (response.status === "FAILED") {
          CommonTemplate.renderLoadingIcon("#bnfxStreamRawdata");
          count++;

          // If not in live mode and max attempts reached, clear interval
          if (mode !== "livemode" && count === maxAttempts) {
            const container = document.querySelector("#bnfxStreamRawdata");
            const data = (
              <NoContent
                id={"norawevents"}
                title={t("norawdata.found.title")}
                description={t("norawdata.found.description")}
              />
            );
            CommonTemplate.ContainerAvailability(container, data);
            clearInterval(intervalId); // Clear the interval after reaching max attempts
          }
        } else {
          // Successful response, clear the interval
          clearInterval(intervalId);
        }
      } else {
        // Handle case where raweventQueryid is not set (if needed)
        clearInterval(intervalId); // Optionally clear if not set
      }
    }, 3000); // Call every 5 seconds
    Stream.rawEventsLiveModeInterval = intervalId;
  },

  fetechqueridresult: async (offset) => {
    const container = document.querySelector("#bnfxStreamRawdata");

    const response = await fetchData(
      "get",
      requestURI["fetech.results.api"] +
        "/" +
        Stream.raweventQueryid +
        "?offset=" +
        offset +
        "&limit=10",
      null,
      null
    );
    let data = null;

    if (response.status === "FAILED") {
      console.log("error feteching results from queryid ");
    } else {
      let output = response.response.result;

      let total = response.response.total;
      data = (
        <RawEvent
          output={output}
          callback={() => Stream.renderRawPagination(total, offset)}
        />
      );
     
    }
    CommonTemplate.ContainerAvailability(container, data);
    return response;
  },
  deletequerid: () => {
    ajax(
      "delete",
      requestURI["delete.queryid.api"] + "/" + Stream.raweventQueryid,
      null,
      {},
      null,
      showError
    );
  },

  streamRawEventsSearchSuggest: (stream) => {
    ajax(
      "get",
      requestURI["list.columns"],
      null,
      {},
      Stream.loadRawEventsSearchLayout,
      showError,
      [stream]
    );
  },
  loadRawEventsSearchLayout: (response, args) => {
    const container = document.querySelector("#bnfxRaweventsloadSearch");

    const data = (
      <LoadDetectionSearch
        autosuggestions={response.data.response}
        streamid={args[0]}
        serachtype="rawevents"
        detectionDataType={Stream.detectionDataType}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },
  renderRawPagination: (total, offset) => {
    if (total > offset) {
      const paginationData = {
        total: total,
        limit: 10,
        offset: offset ? offset : 0,
      };

      const container = document.getElementById("bnfxRawDataPagination");

      const data = (
        <Pagination
          pagination={paginationData}
          onClickFn={Stream.getRawDataPagination}
        />
      );

      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  getRawDataPagination: (offset) => {
    Stream.fetechqueridresult(offset);
  },
  openRawEventsTab: (evt, tabName, removetab, uniqueid) => {
    let removetabname = document.querySelector(`#${removetab + uniqueid}`);
    removetabname.classList.remove("active");

    let elementsToActivate = document.querySelector(`#${tabName + uniqueid}`); // Use class instead of ID for multiple elements

    elementsToActivate.classList.add("active");

    evt.currentTarget.classList.add("active");
  },
  setLastTime: (time) => {
    Stream.lastRawEventsTrendTime = time;
  },

  RawEventsTrendGraph: (stream, livemode) => {
    
    let request = null;
    if (livemode) {
      request = {
        streamid: stream._id,
        query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
        timewindow: Stream.lastRawEventsTrendTime,
        count: livemode,
      };
    } else {
      CommonTemplate.renderLoadingWaveIcon(".raweventstrendchartgraph");
      request = {
        streamid: stream._id,
        query: Stream.selected_freetext ? Stream.selected_freetext : undefined,
      };
    }

    ajax(
      "post",
      requestURI["rawevents.trend.graph.api"],
      null,
      request,
      Stream.renderRaweventsTrendGraphRequest,
      showError
    );
  },
  renderRaweventsTrendGraphRequest: (response) => {
    let status = response.data.response.status;
    const raweventstrendchartgraph = document.querySelector(
      ".raweventstrendchartgraph"
    );
    if (status !== "failed") {
      let output = response.data.response.result;
      const transformedData = Object.entries(output).map(([key, value]) => [
        parseInt(key),
        value,
      ]);
      const lastEntry = transformedData[transformedData.length - 1];

      // Extract the time
      Stream.setLastTime(lastEntry[0]);
      if (Stream.chartInstance) {
        Stream.updateRealTimeData(transformedData);
      } else {
        let raweventtrenddata = (
          <StockTrendChart
            height={100}
            container={"raweventstrendchartgraph"}
            data={transformedData}
            onChartReady={Stream.storeChartInstance}
            onUpdateData={(updateFunction) => {
              Stream.updateChartData = updateFunction; // Store the function for future use
            }}
            onRangeChange={Stream.raweventrangeselectormode}
          />
        );
        CommonTemplate.ContainerAvailability(
          raweventstrendchartgraph,
          raweventtrenddata
        );
      }
    } else {
      const data = null;
      CommonTemplate.ContainerAvailability(raweventstrendchartgraph, data);
    }
  },
  storeChartInstance: (chartInstance) => {
    Stream.chartInstance = chartInstance;
  },

  // Update the chart with new data
  updateRealTimeData: (newData) => {
    if (Stream.chartInstance) {
      newData.forEach((point) => {
        Stream.chartInstance.series[0].addPoint(point, true, true); // Add each point and shift older points off
      });
    }
  },

  raweventliveapi: () => {
    Stream.RawEventsTrendGraph(Stream.selected_stream, true);
  },
  raweventdatalivemode: () => {
    Stream.getRawEvents(Stream.selected_stream, "livemode");
  },
  setRangeSelectorRawevents: (startime, endtime) => {
    Stream.raweventRangeSelector = [startime, endtime];
  },
  raweventrangeselectormode: (startime, endtime) => {
    Stream.setRangeSelectorRawevents(startime, endtime);
    Stream.getRawEvents(Stream.selected_stream, "rangeselector");
  },
};