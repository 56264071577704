// Import necessary dependencies
import { showError } from "../../components/common/DisplayError";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax } from "../../components/common/Service";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import DownloadItems from "./DownloadItems";
import Downloads from "./Downloads";

// Collectors module provides functions for handling data related to collectors.
export const DownloadsScript = {
  // Variable to store the content of the configuration file
  filecontent: null,
  defaulttenant: null,
  previuseps: null,
  downloadoffset: null,
  reportoffset: null,
  existingdownloads: [],
  existingreports: [],

  // Initialize the collectors module
  init: async () => {
    DownloadsScript.loadexports(0);
    DownloadsScript.setdownloadOffset(0);
    DownloadsScript.setreportsOffset(0);
  },

  loadexports: (offset) => {
    let endpoint = `?type=db-export&limit=20&offset=${offset ? offset : 0}`;
    ajax(
      "get",
      requestURI["download.listfiles"] + endpoint,
      null,
      null,
      DownloadsScript.renderfileslist,
      showError,
      [offset]
    );
  },
  renderfileslist: (result, args) => {
    let offset = args[0];
    let response = result.data;
    if (offset === 0) {
      DownloadsScript.existingdownloads.length = 0;
      DownloadsScript.setexistingdownloads(response);
    } else {
      DownloadsScript.setexistingdownloads(response);
    }
    let data = (
      <DownloadItems downloads={DownloadsScript.existingdownloads} />
    );
    let container = document.querySelector("#downloads-exports");
    CommonTemplate.ContainerAvailability(container, data);
  },
  loadreports: (offset) => {
    let endpoint = `?type=system-reports&limit=20&offset=${
      offset ? offset : 0
    }`;
    ajax(
      "get",
      requestURI["download.listfiles"] + endpoint,
      null,
      null,
      DownloadsScript.renderreportsfileslist,
      showError,
      [offset]
    );
  },
  renderreportsfileslist: (result, args) => {
    let offset = args[0];
    let response = result.data;
    if (offset === 0) {
      DownloadsScript.existingreports.length = 0;
      DownloadsScript.setexistingreports(response);
    } else {
      DownloadsScript.setexistingreports(response);
    }
    let data = <DownloadItems downloads={DownloadsScript.existingreports} />;
    let container = document.querySelector("#downloads-imports");
    CommonTemplate.ContainerAvailability(container, data);
  },
  openTab: (evt, tabName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tab-content bnfxdownloads");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("active");
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(tabName).classList.add("active");
    evt.currentTarget.classList.add("active");
    if (tabName === "downloads-exports") {
      DownloadsScript.loadexports(0);
    } else if (tabName === "downloads-imports") {
      DownloadsScript.loadreports(0);
    }
  },

  loadDownloads: () => {
    let data = <Downloads />;
    CommonTemplate.ContainerAvailability(
      document.querySelector("#bnfx-download-panel"),
      data
    );
  },
  loadmore: (type) => {
    if (type === "download") {
      DownloadsScript.setdownloadOffset(DownloadsScript.downloadoffset + 20);
      DownloadsScript.loadexports(DownloadsScript.downloadoffset);
    } else if (type === "reports") {
      DownloadsScript.setreportsOffset(DownloadsScript.reportoffset + 20);
      DownloadsScript.loadreports(DownloadsScript.reportoffset);
    }
  },
  setdownloadOffset: (offset) => {
    DownloadsScript.downloadoffset = offset;
  },
  setreportsOffset: (offset) => {
    DownloadsScript.reportoffset = offset;
  },
  downloadapicall: (type, filename) => {
    let endpoint = `?type=${type}&filename=${filename}`;
    const token = localStorage.getItem("token");
    const bearerToken = "Bearer " + token;
    const headers = {
      Authorization: `${bearerToken}`,
      "Content-type": "application/octet-stream",
    };
    ajax(
      "get",
      requestURI["download.fetch"] + endpoint,
      headers,
      null,
      DownloadsScript.renderdownloaded,
      showError,
      [filename]
    );
  },
  renderdownloaded: (response, args) => {
    const blobData = response.data;
    // Create a Blob from the array buffer
    const blob = new Blob([blobData], { type: "text/csv" }); // Specify MIME type as text/csv for CSV file

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);

    a.download = args[0]; // Set the filename with .csv extension

    // Append the anchor element to the document body
    document.body.appendChild(a);

    // Trigger a click on the anchor element
    a.click();

    // Clean up by removing the anchor element
    document.body.removeChild(a);
  },
  setexistingdownloads: (data) => {
    DownloadsScript.existingdownloads =
      DownloadsScript.existingdownloads.concat(data);
  },
  setexistingreports: (data) => {
    DownloadsScript.existingreports =
      DownloadsScript.existingreports.concat(data);
  },
};
